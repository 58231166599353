import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contracted-spend-period-form"
export default class extends Controller {
  static targets = ["spendPeriodField"]
  spendPeriodFieldTarget: HTMLInputElement

  connect() {}

  periodHasEndDate = () => {
    this.setSpendPeriod("full")
  }

  periodNoEndDate = () => {
    this.setSpendPeriod("monthly")
  }

  setSpendPeriod = (value: string) => {
    this.spendPeriodFieldTarget.value = value
    this.spendPeriodFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
